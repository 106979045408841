<div id="SSOTV" class="flex-container"
  [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}">
  <div class="flex-aside">
    <div class="flex-content">
      <div class="logo">
        <audio id="audio" autostart="false"></audio>
        <logo-image *ngIf="empresa" [empresa]="empresa" [idFranquia]="idFranquia" [imgStyles]="'max-height: 20vh'"
          [imgClass]="'img-responsive'"></logo-image>
      </div>
      <div class="senha-atual" #clickArea (click)="clickMe();">
        <div style="display: flex; flex-direction: column; justify-content: space-evenly; align-items: center; height: 100%; text-align: center;">
          <div>
            <h3 class="titulo">SENHA</h3>
            <span class="senha" id="senha">{{model.senha}} </span>
            <h4 *ngIf="showNome" class="nome">{{model?.nome}}</h4>
          </div>
          <div>
            <h3 class="titulo2">
              {{ getNomeChamada(model.tipoChamada) }}
            </h3>
            <span id="mesa"
              [ngClass]="(model?.mesa === 'ESTOQUE' || model?.mesa === 'CAIXA') ? 'mesa5vw' : 'mesa'">{{model.mesa}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-main">
    <div class="flex-content">
      <div class="video">
        <div class="video-container">
          <div id="player" class="youtubeallin"></div>
        </div>
      </div>
      <div class="ultimas-chamdas">
        <h3>Últimas chamadas</h3>
        <ul>
          <li *ngFor="let h of historico | slice : 0 : 4">
            <ng-container *ngIf="!showNome">
              <span class="u-senha">{{h.senha.senha}}</span>
              <span class="u-mesa" *ngIf="h.fila && h.fila.tipoChamada !== ''">{{h.mesa.nome}}</span>
            </ng-container>
            <ng-container *ngIf="showNome">
              <span class="u-senha">
                {{h.senha.senha}} {{h.fila && h.fila.tipoChamada !== '' ? ' - ' + h.mesa.nome : ''}}
              </span>
              <h3 *ngIf="h.senha.nome">{{h.senha.nome}}</h3>
            </ng-container>
          </li>
        </ul>
      </div>
      <div class="avisos">
        <marquee class="marquee" *ngIf="avisos?.length > 0">
          <ul>
            <li *ngFor="let aviso of avisos">{{aviso.mensagem}}</li>
          </ul>
        </marquee>
      </div>
    </div>
  </div>
</div>
