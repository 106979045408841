<div id="SSOTV" class="flex-container">
  <div class="flex-aside">
    <div class="flex-content" style="justify-content: space-between">
      <div class="logo">
        <audio id="audio" autostart="false"></audio>
        <logo-image
          *ngIf="empresa"
          [empresa]="empresa"
          [idFranquia]="idFranquia"
          [imgStyles]="'max-height: 20vh'"
          [imgClass]="'img-responsive'"
        ></logo-image>
      </div>
      <div
        class="ultimas-chamdas"
        [ngStyle]="{
          color: empresa?.textColor,
          'background-color': empresa?.bgColor,
          'border-color': empresa?.bgColor
        }"
      >
        <h3 [ngStyle]="{ color: empresa?.textColor }">Últimas chamadas</h3>
        <ul>
          <li *ngFor="let h of historico | slice : 0 : 4">
            <ng-container *ngIf="!showNome">
              <span class="u-senha" [ngStyle]="{ color: empresa?.textColor }">{{
                h.senha?.senha
              }}</span>
              <span
                class="u-mesa"
                *ngIf="h.fila && h.fila.tipoChamada !== ''"
                [ngStyle]="{ color: empresa?.textColor }"
                >{{ getNomeChamada(h.fila?.tipoChamada) }}
                {{ h.mesa?.nome }}</span
              >
            </ng-container>

            <ng-container *ngIf="showNome">
              <div
                class="w-full d-flex flex-column justify-content-between"
                style="border: 1px solid"
                [style.borderColor]="hexToRGBA(empresa?.textColor, 1)"
              >
                <div
                  class="d-flex justify-content-between"
                  style="line-height: 3.3vw; padding: 0 20px"
                  [style.background]="hexToRGBA(empresa?.textColor, 1)"
                  [ngStyle]="{ color: empresa?.bgColor }"
                >
                  <span class="u-senha">{{ h.senha?.senha }}</span>
                  <span
                    class="u-mesa"
                    *ngIf="h.fila && h.fila.tipoChamada !== ''"
                    >{{ getNomeChamada(h.fila?.tipoChamada) }}
                    {{ h.mesa?.nome }}</span
                  >
                </div>
                <div class="senhaNome" *ngIf="h.senha.nome">
                  {{ h.senha.nome }}
                </div>
              </div>
            </ng-container>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    class="flex-main"
    #clickArea
    (click)="clickMe()"
    [ngStyle]="{
      color: '#000',
      'background-color': empresa?.textColor,
      'border-left': '1px solid #000',
      'border-color': empresa?.bgColor
    }"
  >
    <div class="flex-content">
      <div class="tv-senha">
        <div class="tv-senha__content">
          <ng-container *ngIf="!showNome">
            <h4 class="tv-senha__titulo">SENHA</h4>
            <div class="tv-senha__senha" id="senha">{{ model.senha }}</div>
            <div class="tv-senha__mesa" id="mesa">
              {{ getNomeChamada(model.tipoChamada) }} {{ model.mesa }}
            </div>
          </ng-container>

          <ng-container *ngIf="showNome">
            <div class="tv-senha__senha" id="senha">{{ model.senha }}</div>
            <div class="tv-senha__mesa" id="mesa">
              {{ getNomeChamada(model.tipoChamada) }} {{ model.mesa }}
            </div>
            <div class="tv-senha__nome" *ngIf="model.nome">
              {{ model.nome }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="avisos"
  [ngStyle]="{
    'border-top': '1px solid #000',
    'border-color': empresa?.bgColor
  }"
>
  <marquee class="marquee" *ngIf="avisos?.length > 0">
    <ul>
      <li *ngFor="let aviso of avisos">{{ aviso.mensagem }}</li>
    </ul>
  </marquee>
</div>
