<layout-default *ngIf="tv && tv.layout === 'DEFAULT'" [model]="model" [empresa]="empresa" [idFranquia]="idFranquia" [historico]="historico"
  [avisos]="avisos" [showNome]="showNome">
</layout-default>

<layout-1 *ngIf="tv && tv?.layout === 'LAYOUT_1'" [model]="model" [empresa]="empresa" [idFranquia]="idFranquia" [historico]="historico"
  [avisos]="avisos" [showNome]="showNome">
</layout-1>

<layout-2 *ngIf="tv && tv?.layout === 'LAYOUT_2'" [model]="model" [empresa]="empresa" [idFranquia]="idFranquia" [historico]="historico"
  [avisos]="avisos" [showNome]="showNome">
</layout-2>

<layout-3 *ngIf="tv && tv?.layout === 'LAYOUT_3'" [model]="model" [empresa]="empresa" [historico]="historico"
  [avisos]="avisos" [showNome]="showNome">
</layout-3>

<layout-4 *ngIf="tv && tv?.layout === 'LAYOUT_4'" [model]="model" [empresa]="empresa" [historico]="historico"
  [avisos]="avisos" [showNome]="showNome">
</layout-4>

<layout-5 *ngIf="tv && tv?.layout === 'LAYOUT_5'" [model]="model" [empresa]="empresa" [historico]="historico"
  [avisos]="avisos" [showNome]="showNome" [tv]="tv" 
  [previsaoAtendimento]="previsaoAtendimento" [countSenhasChamada]="countSenhasChamada">
</layout-5>

<div style="display: none">
  <a (click)="onBtnAtualizar()" #btnAtualizar class="btn btn-primary btn-sm ">ATUALIZA LISTA CHAMADA</a>
  <a (click)="onBtnOnline()" #btnOnline class="btn btn-primary btn-sm ">ENTRAR ONLINE</a>
  <a (click)="onBtnAvisos()" #btnAvisos class="btn btn-primary btn-sm ">VISUALIZAR AVISOS</a>
</div>
