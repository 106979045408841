<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2><i class="fa fa-tv"></i> TV</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/franquia']">{{franquia?.nome}}</a>
      </li>
      <li class="active">
        <strong>TVs</strong>
      </li>
    </ol>
  </div>
</div>

<div class="row wrapper wrapper-content animated fadeIn">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5>TVs</h5>
        <div class="ibox-tools">
          <!-- <a [routerLink]="['/tv/add/franquia/', franquia?._id]" class="btn btn-primary btn-xs">
                        <i class="fa fa-plus"></i> Adicionar</a> -->
        </div>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Descrição</th>
                <th>Playlist</th>
                <th width="15%" style="text-align: right;">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of tvs">
                <th><i class="fa fa-tv fa-2x"></i> {{item.nome}}</th>
                <td>{{item.descricao}}</td>
                <td>{{item.playlist.nome}} <small>({{item.playlist.url}})</small></td>
                <td style="text-align: right;">
                  <div class="input-group-btn">
                    <a class="btn btn-primary btn-sm btn-bitbucket" target="_blank"
                      [routerLink]="['/app/itv', item._id]">
                      <i class="fa fa-share"></i>
                    </a>
                    <!-- <a class="btn btn-white btn-sm btn-bitbucket" [routerLink]="['/tv/edit/', item._id]">
                                            <i class="fa fa-edit"></i>
                                        </a>
                                        <a class="btn btn-white btn-sm btn-bitbucket" data-toggle="modal" href="#deleteModel"
                                            (click)="selecionar(item)">
                                            <i class="fa fa-trash"></i>
                                        </a> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal inmodal" id="deleteModel" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">Remover TV</h4>
      </div>
      <div class="modal-body">
        <div align="center">
          <p>
            Esta ação não poderá ser desfeita. Tem certeza que deseja excluir a fila
            <b>{{tvSelecionada?.nome}}</b>?
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="delete()">Confirmar</button>
      </div>
    </div>
  </div>
</div>