<div id="SSOTV" class="painel-tv">
  <audio id="audio" autostart="false"></audio>
  <div class="painel-tv__content">
    <div
      class="tv-senha"
      #clickArea
      (click)="clickMe()"
      [ngStyle]="{
        color: empresa?.textColor,
        'background-color': empresa?.bgColor,
        'border-color': empresa?.bgColor
      }"
    >
      <div class="tv-senha__content">
        <ng-container *ngIf="!showNome">
          <h4 class="tv-senha__titulo">SENHA</h4>
          <div class="tv-senha__senha" id="senha">{{ model.senha }}</div>
          <div class="tv-senha__mesa" id="mesa">
            {{ getNomeChamada(model.tipoChamada) }} {{ model.mesa }}
          </div>
        </ng-container>
        <ng-container *ngIf="showNome">
          <div class="tv-nome__senha" id="senha">{{ model.senha }}</div>
          <div class="tv-nome__mesa" id="mesa">
            {{ getNomeChamada(model.tipoChamada) }} {{ model.mesa }}
          </div>
          <div class="tv-nome__nome" *ngIf="model.nome">{{ model.nome }}</div>
        </ng-container>
      </div>
    </div>

    <div class="ultimas-chamdas">
      <h3>Últimas chamadas</h3>
      <ul>
        <li
        *ngFor="let h of historico | slice : 0 : 4"
          style="border: 2px solid #ccc; border-radius: 20px; padding: 20px"
        >
          <ng-container *ngIf="!showNome">
            <span class="u-senha">{{ h.senha.senha }}</span>
            <span class="u-mesa" *ngIf="h.fila && h.fila.tipoChamada !== ''"
              >{{ getNomeChamada(h.fila?.tipoChamada) }} {{ h.mesa.nome }}</span
            >
          </ng-container>

          <ng-container *ngIf="showNome">
            <span class="u-nome-senha">
              {{ h.senha.senha }}
            </span>
            <span
              class="u-nome-mesa"
              *ngIf="h.fila && h.fila.tipoChamada !== ''"
              >{{ getNomeChamada(h.fila?.tipoChamada) }} {{ h.mesa.nome }}</span
            >
            <div class="u-nome-nome" *ngIf="h.senha.nome">
              {{ h.senha.nome }}
            </div>
          </ng-container>
        </li>
      </ul>
    </div>
    <div class="avisos">
      <marquee class="marquee" *ngIf="avisos?.length > 0">
        <ul>
          <li *ngFor="let aviso of avisos">{{ aviso.mensagem }}</li>
        </ul>
      </marquee>
    </div>
  </div>
</div>
