import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'lib-smart-core';
import { AppTVComponent } from './modules/apps/app-tv.component';
import { LoginComponent } from './modules/login/login.component';
import { TVListaComponent } from './modules/tv/tv-lista.component';

const appRoutes: Routes = [
  { path: '', component: TVListaComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },

  // tv
  { path: 'tv/:idFranquia', component: TVListaComponent, canActivate: [AuthGuard] },

  // apps
  { path: 'app/itv/:idTV', component: AppTVComponent },
  { path: 'app/tv/:idEmpresa/:idFranquia', component: AppTVComponent },

  // tv anonima
  { path: 'app/itv/:idTV/:incognito', component: AppTVComponent },

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {

}
