import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-smartline-web-tv',
  templateUrl: './app.component.html',
})
export class AppComponent {

  FRANQUIA_SESSION;

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  usuarioLogado(): Boolean {
    let currentUserTV = JSON.parse(localStorage.getItem('currentUserTV'));
    if (currentUserTV) {
      this.FRANQUIA_SESSION = currentUserTV.franquia;
    }
    return this.FRANQUIA_SESSION;
  }

  currentUser(): any {
    return JSON.parse(localStorage.getItem('currentUserTV'));
  }

}