import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Franquia, UtilHelper } from 'lib-smart-core';
import { Constantes } from '../../../environments/constantes';


@Component({
  selector: 'menu-component',
  templateUrl: './menu.component.html'
})

export class MenuComponent implements OnInit {

  imageDefaultProfile: string = UtilHelper.no_image_user;

  currentUserTV: any;

  FRANQUIA_SESSION: Franquia;

  ngOnInit(): void {
    this.currentUserTV = JSON.parse(localStorage.getItem(Constantes.currentUser));
    console.log(this.currentUserTV);
  }

  constructor(
    private router: Router,
  ) {
  }

}