import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'lib-smart-core';
import { AppTVComponent } from './app-tv.component';
import { Layout1Component } from './layout-1/layout-1.component';
import { Layout2Component } from './layout-2/layout-2.component';
import { Layout3Component } from './layout-3/layout-3.component';
import { Layout4Component } from './layout-4/layout-4.component';
import { Layout5Component } from './layout-5/layout-5.component';
import { LayoutDefaultComponent } from './layout-default/layout-default.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule
  ],
  declarations: [
    AppTVComponent,
    LayoutDefaultComponent,
    Layout1Component,
    Layout2Component,
    Layout3Component,
    Layout4Component,
    Layout5Component,
  ],
  exports: [
    AppTVComponent,
    LayoutDefaultComponent,
    Layout1Component,
    Layout2Component,
    Layout3Component,
    Layout4Component,
    Layout5Component,
  ],
  providers: [
  ]
})
export class AppsModule { }
