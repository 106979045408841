<div id="SSOTV" class="painel-tv">
  <audio id="audio" autostart="false"></audio>
  <div class="painel-tv__content">
    <div
      class="tv-senha"
      #clickArea
      (click)="clickMe()"
      [ngStyle]="{
        color: empresa?.textColor,
        'background-color': empresa?.bgColor,
        'border-color': empresa?.bgColor
      }"
    >
      <div class="tv-senha__content">
        <div class="tv-senha__senha" id="mesa">
          <strong>{{ model.senha }}</strong> -
          {{ getNomeChamada(model.tipoChamada) }} {{ model.mesa }}
        </div>
        <div class="tv-senha__nome" id="senha">
          {{ model.nome ? model.nome : "" }}
        </div>
      </div>
    </div>

    <div class="ultimas-chamdas">
      <h3>Últimas chamadas</h3>
      <ul>
        <li
          *ngFor="let h of historico | slice : 0 : 4"
          style="border: 2px solid #ccc; border-radius: 20px"
        >
          <span class="u-senha">{{ h.senha.senha }}</span>
          <span class="u-mesa"
            >{{ getNomeChamada(model.tipoChamada) }} {{ model.mesa }}</span
          >
          <span class="u-nome">{{ h.senha.nome }}</span>
        </li>
      </ul>
    </div>
    <div class="avisos">
      <marquee class="marquee" *ngIf="avisos?.length > 0">
        <ul>
          <li *ngFor="let aviso of avisos">{{ aviso.mensagem }}</li>
        </ul>
      </marquee>
    </div>
  </div>
</div>
