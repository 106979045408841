<nav class="navbar-default navbar-static-side" role="navigation" id="MENU_NOTV">
  <div class="sidebar-collapse">
    <ul class="nav metismenu" id="side-menu">
      <li class="nav-header">
        <div class="dropdown profile-element">
          <!-- <span>
                        <img alt="image" class="img-circle" src="{{imageDefaultProfile}}" style="width: 42px;">
                    </span> -->
          <a data-toggle="dropdown" class="dropdown-toggle" href="#">
            <span class="clear">
              <span class="block m-t-xs">
                <strong class="font-bold">{{currentUserTV?.franquia.nome}}</strong>
              </span>
              <!-- <span class="text-muted text-xs block">{{currentUserTV?.usuario.email}}
                                <b class="caret"></b>
                            </span> -->
            </span>
          </a>
          <ul class="dropdown-menu animated fadeInRight m-t-xs">
            <li>
              <a [routerLink]="['/login']">Logout</a>
            </li>
          </ul>
        </div>
        <div class="logo-element">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-20 0 100.30 50">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_3" data-name="Layer 3">
                <path d="M51.38,24,39.55,44.5H15.88L4,24,15.88,3.5H39.55ZM32.94,3.67,23,24.34h9.92L23,44.33"
                  fill="none" />
                <path d="M51.38,24,39.55,44.5H15.88L4,24,15.88,3.5H39.55ZM32.94,3.67,23,24.34h9.92L23,44.33" fill="none"
                  stroke="#29abe2" stroke-miterlimit="10" stroke-width="7" />
              </g>
            </g>
          </svg>
        </div>
      </li>
      <li routerLinkActive="active">
        <a [routerLink]="['/']">
          <i class="fa fa-tv"></i>
          <span class="nav-label">TVs</span>
        </a>
      </li>

    </ul>

  </div>
</nav>