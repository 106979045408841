import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AlertModule, AuthGuard, CONSTANTES, ENV, customHttpProvider } from 'lib-smart-core';
import { Constantes } from '../environments/constantes';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppsModule } from './modules/apps/apps.module';
import { LayoutModule } from './modules/layout/layout.module';
import { LoginComponent } from './modules/login/login.component';
import { TVModule } from './modules/tv/tv.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    LayoutModule,
    AppsModule,
    TVModule,
    AlertModule,
  ],
  providers: [
    AuthGuard,
    customHttpProvider,
    { provide: ENV, useValue: environment },
    { provide: CONSTANTES, useValue: Constantes },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
