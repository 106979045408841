import { AfterContentInit, Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractComponent, Aviso, Chamada, Empresa } from 'lib-smart-core';
import { timer } from 'rxjs';

@Component({
  selector: 'layout-4',
  styleUrls: ['./layout-4.component.css'],
  templateUrl: './layout-4.component.html'
})
export class Layout4Component extends AbstractComponent implements OnInit, AfterContentInit {

  @Input('model') model: any;
  @Input('empresa') empresa: Empresa;
  @Input('historico') historico: Chamada[];
  @Input('avisos') avisos: Aviso[];
  @Input('showNome') showNome: boolean = false;
  @Input('showAvisos') showAvisos: boolean = false;
  @ViewChild('clickArea') clickArea: ElementRef;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  clickMe() {
    console.log('click area okay');
  }

  ngAfterContentInit(): void {
    // click de area para habilitar o som
    timer(2000).subscribe(() => this.clickArea.nativeElement.click());
  }

}
