import { AfterContentInit, Component, ElementRef, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractComponent, Aviso, Chamada, Empresa, TV } from 'lib-smart-core';
import { timer } from 'rxjs';

@Component({
  selector: 'layout-5',
  styleUrls: ['./layout-5.component.css'],
  templateUrl: './layout-5.component.html'
})
export class Layout5Component extends AbstractComponent implements OnInit, OnChanges, AfterContentInit {

  @Input('tv') tv: TV;
  @Input('model') model: any;
  @Input('empresa') empresa: Empresa;
  @Input('historico') historico: Chamada[];
  @Input('avisos') avisos: Aviso[];
  @Input('showNome') showNome: boolean = false;
  @Input('showAvisos') showAvisos: boolean = false;

  @Input('previsaoAtendimento') previsaoAtendimento: number = 0;
  @Input('countSenhasChamada') countSenhasChamada: number = 0;

  @ViewChild('clickArea') clickArea: ElementRef;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.tv.tipoTempoPrevistoAtendimento === 'MEDIA_CHAMADA') {
      if (changes['previsaoAtendimento'] && changes['previsaoAtendimento'].currentValue) {
        const pa = changes['previsaoAtendimento'].currentValue;
        if (!!pa && pa.length > 0) {
          const tempoMedioAtendimento = pa[0].tempoMedioAtendimento;
          this.previsaoAtendimento = tempoMedioAtendimento?.toFixed(0);
        }
      }
    }
    if (changes['countSenhasChamada'] && changes['countSenhasChamada'].currentValue) {
      this.countSenhasChamada = changes['countSenhasChamada'].currentValue;
      if (this.tv.tipoTempoPrevistoAtendimento === 'TEMPO_ESPECIFICO') {
        const tempoEspecificoAtendimento = this.tv.tempoEspecificoAtendimento;
        const tempoMedioAtendimento = +tempoEspecificoAtendimento * +this.countSenhasChamada;
        this.previsaoAtendimento = +tempoMedioAtendimento?.toFixed(0);
      }
    }
  }

  clickMe() {
  }

  ngAfterContentInit(): void {
    timer(2000).subscribe(() => this.clickArea.nativeElement.click());
  }

}
