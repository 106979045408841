import { AfterContentInit, ChangeDetectionStrategy, Component, ElementRef, Injector, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractComponent, Aviso, Chamada, Empresa } from 'lib-smart-core';
import { timer } from 'rxjs';

@Component({
  selector: 'layout-default',
  styleUrls: ['./layout-default.component.css'],
  templateUrl: './layout-default.component.html'
})
export class LayoutDefaultComponent extends AbstractComponent implements OnInit, AfterContentInit {

  @Input('model') model: any;
  @Input('empresa') empresa: Empresa;
  @Input('historico') historico: Chamada[];
  @Input('avisos') avisos: Aviso[];
  @Input('idFranquia') idFranquia: string;
  @Input('showNome') showNome: boolean = false;
  @ViewChild('clickArea') clickArea: ElementRef;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  clickMe() {
    console.log('click area okay');
  }

  ngAfterContentInit(): void {
    // click de area para habilitar o som
    timer(2000).subscribe(() => this.clickArea.nativeElement.click());
  }


}
